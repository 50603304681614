import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import Seo from './../components/Layout/Seo'
import GoogleMaps from '../components/Map/GoogleMaps'
import Title from './../components/Title'
import InstagramFeed from './../components/Instagram/InstagramFeed'

const Wrapper = styled.section`
    position: relative;

    padding: 8rem 0 0;

    ${props => props.theme.above.desktop`
        padding: 20rem 0 0;
    `}
`

const MapWrapper = styled.div`
    position: relative;

    width: 100%;
    height: 100vh;

    max-height: 80rem;
`

const StyledTitle = styled(Title)`
    ${props => props.theme.above.desktop`
        margin-bottom: 12rem;

        .line {
            display: none;
        }
    `}
`

const TourPage = ({
    data: {
        instagram,
        tour: {
            data: {
                content_title,
                content_subtitle,
                content_description,
                map,
                seo_title,
                seo_description,
                seo_keywords,
                seo_image
            }
        }
    }
}) => {
    return (
        <>
            <Seo
                title={seo_title}
                description={seo_description.text}
                keywords={seo_keywords}
                image={seo_image}
            />
            <Wrapper>
                <StyledTitle 
                    title={content_title.text}
                    subtitle={content_subtitle}
                    description={content_description}
                    alignment='center'
                    html
                />
                <MapWrapper>
                    <GoogleMaps data={map} />
                </MapWrapper>
            </Wrapper>
            <InstagramFeed
                copy={instagram}
            />
        </>
    )
}

export const QUERY_TOURPAGE = graphql`
    query TourPage($id: String!, $lang: String!) {
        tour: prismicTourPage(id: { eq: $id }) {
            id
            lang
            ...TourPageQuery
        }
        instagram: prismicInstagramComponent(lang: { eq: $lang }) {
            id
            lang
            ...InstagramQuery
        }
    }
`

export default TourPage