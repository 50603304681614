import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { GoogleApiWrapper, Map } from "google-maps-react"

import { mapStyles } from "./Style"

import InfoWindow from "./InfoWindow"
import Marker from "./Marker"

const Inner = styled.div`
    width: 20rem;

    @media screen and (min-width: 1023px) {
        width: 30rem;
    }
`

const ImageWrapper = styled.div`
    position: relative;

    width: 100%;
    height: 100%;

    overflow: hidden;

    background-color: #252525;

    padding-top: 60%;

    @media screen and (min-width: 1023px) {
        padding-top: 100%;
    }
`

const Image = styled.img`
    position: absolute !important;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;
`

const Content = styled.div`
    background-color: #fff;

    margin-top: -1px;
    padding: 1.5rem 1.5rem;

    text-align: center;

    @media screen and (min-width: 1023px) {
        padding: 2rem 3rem 2.2rem 3rem;
    }
`

const Title = styled.h4`
    font-family: MinionSemibold;
    font-size: 1.5rem;
    line-height: 2rem;

    color: #1a1919;

    @media screen and (min-width: 1023px) {
        font-size: 2rem;
        line-height: 3rem;
    }
`

const Location = styled.span`
    display: block;

    font-family: CircularBook;
    font-size: 1rem;
    line-height: 1rem;

    letter-spacing: 0.05rem;

    @media screen and (min-width: 1023px) {
        font-size: 1.2rem;
        line-height: 1.2rem;
    }
`

class GoogleMapsContainer extends React.Component {
    constructor() {
        super()

        this.timer = null
    }

    state = {
        activeMarker: {},
        selectedPlace: {},
        showingInfoWindow: false,
        mapLoaded: false,
    }

    onMarkerClick = (props, marker) => {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true,
        })
    }

    onInfoWindowClose = () => {
        this.setState({
            activeMarker: null,
            showingInfoWindow: false,
        })
    }

    onMapClicked = () => {
        if (this.state.showingInfoWindow) {
            this.setState({
                activeMarker: null,
                showingInfoWindow: false,
            })
        }
    }

    handleMapIdle = () => {
        this.setState({
            mapLoaded: true,
        })
    }

    onMarkerMounted = e => {
        if (this.timer) return
        this.timer = setTimeout(() => {
            this.onMarkerClick(e.props, e.marker)
        }, 500)
    }

    componentWillUnmount = () => {
        if (this.timer) clearTimeout(this.timer)
    }

    render() {
        if (!this.props.loaded)
            return (
                <div
                    style={{
                        height: "100%",
                        position: "absolute",
                        width: "100%",
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                    }}
                ></div>
            )

        return (
            <Map
                className="map"
                google={this.props.google}
                onClick={this.onMapClicked}
                style={{ height: "100%", position: "relative", width: "100%" }}
                styles={mapStyles}
                zoom={7}
                onIdle={this.handleMapIdle}
                initialCenter={{
                    lat: 52.074772,
                    lng: 4.65878,
                }}
            >
                {this.state.mapLoaded &&
                    this.props.data[0].items.map((item, index) => {
                        return (
                            <Marker
                                key={index}
                                ref={index == 1 && this.onMarkerMounted}
                                name={item.upload_title.text}
                                data={item}
                                onClick={this.onMarkerClick}
                                position={{
                                    lat: item.upload_location.latitude,
                                    lng: item.upload_location.longitude,
                                }}
                            />
                        )
                    })}

                {this.onInfoWindowClose && (
                    <InfoWindow
                        marker={this.state.activeMarker}
                        onClose={this.onInfoWindowClose}
                        visible={this.state.showingInfoWindow}
                    >
                        {this.state.selectedPlace.data && (
                            <Inner>
                                <ImageWrapper>
                                    <Image
                                        src={
                                            this.state.selectedPlace.data
                                                .upload_image.fluid.src
                                        }
                                        alt={
                                            this.state.selectedPlace.data
                                                .upload_image.alt
                                        }
                                    />
                                </ImageWrapper>
                                <Content>
                                    <Title>
                                        {
                                            this.state.selectedPlace.data
                                                .upload_title.text
                                        }
                                    </Title>
                                    <Location>
                                        {
                                            this.state.selectedPlace.data
                                                .upload_placename
                                        }
                                    </Location>
                                </Content>
                            </Inner>
                        )}
                    </InfoWindow>
                )}
            </Map>
        )
    }
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyBOHP44UIAvCjhgHAicvCZWJx8rIB8zRN4",
})(GoogleMapsContainer)
